<template>
  <CreateUpdateTemplate customClass="user-create create-form">
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create a new User</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save User
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="userForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <v-container fluid>
          <v-row>
            <v-col
              class="custom-content-container pt-0"
              cols="12"
              :md="9"
              :sm="9"
            >
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll"
                style="max-height: 60vh; position: relative"
              > -->
              <v-row>
                <v-col
                  class="iv-custom-field"
                  cols="3"
                  md="3"
                  sm="3"
                  v-on:mouseleave="imageTemplate = false"
                  v-on:mouseover="imageTemplate = true"
                >
                  <template>
                    <v-img
                      :lazy-src="$defaultProfileImage"
                      :src="getProfileImage"
                      max-height="200"
                      max-width="200"
                      height="200px"
                      width="200px"
                      class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
                    >
                    </v-img>
                    <div class="margin-auto">
                      <v-btn
                        class="my-4 custom-bold-button width-100"
                        outlined
                        small
                        v-on:click="selectImage"
                        color="cyan"
                        :loading="imageLoading"
                        :disabled="imageLoading"
                      >
                        <template v-if="getProfileImage">Change Image</template>
                        <template v-else>Upload Image</template>
                      </v-btn>
                      <div class="d-none">
                        <v-file-input
                          ref="uploadImage"
                          v-on:change="updateProfileImage"
                          :rules="imageRules"
                          accept="image/png, image/jpeg, image/jpg"
                        ></v-file-input>
                      </div>
                    </div>
                  </template>
                </v-col>
                <v-col md="9" class="py-0 pt-4">
                  <v-row>
                    <v-col md="4" class="py-0">
                      <v-select
                        :items="salutationList"
                        dense
                        filled
                        item-text="text"
                        item-value="value"
                        item-color="cyan"
                        label="Salutation"
                        solo
                        flat
                        color="cyan"
                        v-model.trim="updateUser.title"
                        :rules="[
                          validateRules.required(
                            updateUser.title,
                            'Salutation'
                          ),
                        ]"
                      ></v-select>
                    </v-col>
                    <v-col md="4" class="py-0">
                      <v-text-field
                        dense
                        filled
                        label="First Name"
                        solo
                        flat
                        color="cyan"
                        v-model.trim="updateUser.first_name"
                        :rules="[
                          validateRules.required(
                            updateUser.first_name,
                            'First Name'
                          ),
                          validateRules.minLength(
                            updateUser.first_name,
                            'First Name',
                            2
                          ),
                          validateRules.maxLength(
                            updateUser.first_name,
                            'First Name',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" class="py-0">
                      <v-text-field
                        dense
                        filled
                        label="Last Name"
                        solo
                        flat
                        color="cyan"
                        v-model.trim="updateUser.last_name"
                        :rules="[
                          validateRules.required(
                            updateUser.last_name,
                            'Last Name'
                          ),
                          validateRules.minLength(
                            updateUser.last_name,
                            'Last Name',
                            2
                          ),
                          validateRules.maxLength(
                            updateUser.last_name,
                            'Last Name',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4" class="py-0">
                      <v-text-field
                        dense
                        filled
                        label="User Name"
                        solo
                        flat
                        color="cyan"
                        v-model.trim="updateUser.user_name"
                        :rules="[
                          validateRules.required(
                            updateUser.user_name,
                            'User Name'
                          ),
                          validateRules.minLength(
                            updateUser.user_name,
                            'User Name',
                            2
                          ),
                          validateRules.maxLength(
                            updateUser.user_name,
                            'User Name',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" class="py-0">
                      <v-text-field
                        dense
                        filled
                        label="User Email"
                        solo
                        flat
                        color="cyan"
                        v-model.trim="updateUser.user_email"
                        :rules="[
                          validateRules.validEmail(
                            updateUser.user_email,
                            'User Email'
                          ),
                          validateRules.required(
                            updateUser.user_email,
                            'User Email'
                          ),
                          validateRules.minLength(
                            updateUser.user_email,
                            'User Email',
                            2
                          ),
                          validateRules.maxLength(
                            updateUser.user_email,
                            'User Email',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" class="py-0">
                      <v-text-field
                        dense
                        filled
                        label="Phone Number"
                        solo
                        flat
                        v-mask="currentPhoneMask"
                        color="cyan"
                        v-model.trim="updateUser.phone_number"
                        :rules="[
                          validateRules.required(
                            updateUser.phone_number,
                            'Phone Number'
                          ),
                          validateRules.minLength(
                            updateUser.phone_number,
                            'Phone Number',
                            2
                          ),
                          validateRules.maxLength(
                            updateUser.phone_number,
                            'Phone Number',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6" class="py-0">
                      <v-select
                        :items="roles"
                        dense
                        filled
                        item-text="name"
                        item-value="id"
                        item-color="cyan"
                        label="Role"
                        solo
                        flat
                        v-model.trim="updateUser.role"
                        color="cyan"
                        hide-details
                        :rules="[
                          validateRules.required(updateUser.role, 'Role'),
                        ]"
                      ></v-select>
                      <em class="font-size-10" v-if="updateUser.role == 1"
                        >Unrestricted access to all modules.</em
                      >
                    </v-col>
                    <v-col md="6" class="py-0">
                      <v-text-field
                        dense
                        filled
                        label="Display Name"
                        solo
                        flat
                        color="cyan"
                        v-model.trim="updateUser.display_name"
                        :rules="[
                          validateRules.required(
                            updateUser.display_name,
                            'Display Name'
                          ),
                          validateRules.minLength(
                            updateUser.display_name,
                            'Display Name',
                            2
                          ),
                          validateRules.maxLength(
                            updateUser.display_name,
                            'Display Name',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- </perfect-scrollbar> -->
            </v-col>
            <v-col
              class="custom-sidebar-container grey lighten-3"
              cols="12"
              md="3"
              sm="3"
            >
              <template v-for="(fieldInfo, indx) in fieldDescriptions">
                <div
                  v-if="fieldInfo.field == currentActiveField"
                  :key="indx"
                  class="help-sidebar"
                >
                  <div class="help-sidebar-header">
                    <h3 v-if="fieldInfo.title" class="section-header-title">
                      {{ fieldInfo.title }}
                    </h3>
                  </div>
                  <p
                    v-if="fieldInfo.description"
                    class="help-sidebar-description"
                  >
                    {{ fieldInfo.description }}
                  </p>
                  <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
                    {{ fieldInfo.footer }}
                  </h5>
                  <p
                    v-if="fieldInfo.footer_content"
                    class="help-sidebar-footer-text"
                  >
                    {{ fieldInfo.footer_content }}
                  </p>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST, GET } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";

export default {
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  name: "user-create",
  data() {
    return {
      roles: [],
      updateUser: {
        role: null,
        title: null,
        first_name: null,
        last_name: null,
        display_name: null,
        profile_logo: null,
      },
      imageTemplate: false,
      imageLoading: false,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  components: {
    CreateUpdateTemplate,
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.updateUser.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getRoles() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "role" })
        .then(({ data }) => {
          _this.roles = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    createDisplayName() {
      const _this = this;
      let firstname = "";
      if (_this.updateUser.first_name) {
        firstname = _this.updateUser.first_name.toLowerCase();
      }
      let lastname = "";
      if (_this.updateUser.last_name) {
        lastname = _this.updateUser.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    async onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.userForm.validate()) {
          return false;
        }

        _this.formLoading = true;

        if (_this.lodash.isEmpty(_this.updateUser.profile_logo) === false) {
          _this.updateUser.profile_logo = _this.updateUser.profile_logo.id;
        }

        _this.$store
          .dispatch(POST, {
            url: "user",
            data: _this.updateUser,
          })
          .then(() => {
            _this.$router.push(
              _this.getDefaultRoute("user", {
                query: {
                  status: "active",
                },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
  },
  mounted() {
    this.getRoles();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "User", route: "user" },
      { title: "Create" },
    ]);
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.updateUser.profile_logo) === false
        ? this.updateUser.profile_logo.file.url
        : null;
    },
  },
};
</script>
